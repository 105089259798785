import React, { Fragment } from 'react'
import { isMobile } from 'react-device-detect'

import Slider from 'react-slick'

import HeroPageComponent from '../../components/HeroPage'
import FeedbackComponent from '../../components/Feedback'
import ContactComponent from '../../components/Contact'
import Footer from '../../components/Footer'

import heroPage from '../../assets/aboutHeader.png'
import timeline from '../../assets/timeline.svg'
import timelineDsk from '../../assets/timelineDsk.svg'
import rdoPhoto from '../../assets/Foto_Book.png'
import iconIn from '../../assets/bigIconIn.png'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import './About.scss'

const slideSettings = {
  dots: true,
  fade: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
}

const arrayMotivation = [
  {
    description: 'que la autenticidad y la honestidad son claves en la construcción de una marca.',
  },
  {
    description:
      'que el branding involucra todos los puntos en que la marca se relaciona con sus clientes.',
  },
  {
    description:
      'que en la pesquisa, la “guata” (intuición y empatía) es tan importante como la data.',
  },
  {
    description: 'que una marca es tanto una promesa, como un compromiso y una experiencia.',
  },
]

const About = () => {
  return (
    <Fragment>
      <section className="container container-aboutHeroPage container-container-heroPage">
        <HeroPageComponent breadcrumb="Nosotros" title="Branding desde una mirada holística" />
      </section>

      <div className="container container-heroAbout">
        <div className="wrapper-img">
          <img src={heroPage} alt="" />
        </div>

        <div className="container wrapper-heroAbout">
          <h2>Somos una consultora de branding {isMobile ? '' : <br />} con mirada holística.</h2>

          <p>
            Pareciera ser otro de esos términos rebuscados, pero significa que trabajamos el diseño
            y construcción de una marca desde una mirada que abarca todas las aristas y puntos de
            vinculación con sus clientes, redefiniendo su comunicación, vínculos e incluso su modelo
            de negocios.
          </p>
        </div>
      </div>

      <section className="container container-about">
        <div className="wrapper-titleAbout">
          <h2>Trabajamos bajo un proceso integrado {isMobile ? '' : <br />} con el cliente</h2>
          <p>
            Un trabajo colaborativo que nos permite descubrir la auténtica potencialidad de tu
            marca.
          </p>
        </div>

        <div className="wrapper-descriptionAbout">
          <img className="imgMbl" src={timeline} alt="" />
          <img className="imgDsk" src={timelineDsk} alt="" />
        </div>
      </section>

      <section className="container container-motivation">
        <div className="wrapper-titleMotivation">
          <h2>El credo {isMobile ? '' : <br />} que nos mueve</h2>
        </div>

        <div className="wrapper-slideMotivation">
          <Slider {...slideSettings}>
            {arrayMotivation.map((card, index) => (
              <div key={index} className="slide-motivation">
                <p>
                  <strong>Creemos</strong> {card.description}
                </p>
              </div>
            ))}
          </Slider>
        </div>

        <div className="wrapper-gridMotivation">
          {arrayMotivation.map((card, index) => (
            <div key={index} className="card-motivation">
              <p>
                <strong>Creemos</strong> {card.description}
              </p>
            </div>
          ))}
        </div>

        <div className="wrapper-description">
          <p>
            Pero lo principal, es que <br /> creemos en las marcas <br /> que buscan generar <br />{' '}
            un
            <strong> impacto positivo </strong> <br />
            en sus clientes, <br /> comunidades y el mundo.
          </p>
        </div>
      </section>

      <section className="container container-experience">
        <div className="wrapper-experience">
          <div className="wrapper-experience-l">
            <div className="wrapper-imgDsk">
              <img src={rdoPhoto} alt="" />
            </div>
          </div>

          <div className="wrapper-experience-r">
            <div className="wrapper-title">
              <h2>
                <b>Digno</b> es la visión <br /> sobre el branding de
              </h2>
              <h1>Rodrigo Maragaño</h1>
            </div>

            <div className="wrapper-imgMbl">
              <img src={rdoPhoto} alt="" />
            </div>

            <div className="wrapper-description">
              <p>
                25 años trabajando en branding desde el diseño {isMobile ? '' : <br />} de
                identidad, estrategia, investigación y experiencias, principalmente en entornos
                digitales.
              </p>

              <a target="_blank" href="https://linkedin.com/in/rodrigomaragano" rel="noreferrer">
                <img src={iconIn} alt="" />
                linkedin.com/in/rodrigomaragano
              </a>
            </div>
          </div>
        </div>
      </section>

      <FeedbackComponent />

      <ContactComponent />

      <Footer />
    </Fragment>
  )
}

export default About
