import React from 'react'

import logoFooter from '../../assets/logoFooter.png'
import logoCD from '../../assets/chdLogo.png'

import './Footer.scss'

const Footer = () => {
  return (
    <footer>
      <div className="container container-footer">
        <div className="wrapper-imageFooter">
          <img src={logoFooter} alt="" />
        </div>
        <div className="wrapper-footer">
          <ul>
            <li>
              © <b>Digno </b> Consultores de Marca
            </li>
            <li>La Concepción 140, Oficina 102.</li>
            <li>Providencia, Santiago de Chile.</li>

            <li className="addDesktop">
              La Concepción 140, Oficina 102. Providencia, Santiago de Chile.
            </li>

            <li>
              <b>Teléfono: </b> +56 22 840 9500.
            </li>
            <li>
              <b>E-mail: </b>
              <a href="mailto:hablemos@digno.cl"> hablemos@digno.cl</a>
            </li>

            <li>
              <b>Teléfono: </b>+56 22 840 9500.<b> E-mail: </b>
              <a href="mailto:hablemos@digno.cl">hablemos@digno.cl</a>
            </li>
          </ul>
          <img src={logoCD} alt="" />
        </div>
      </div>
    </footer>
  )
}

export default Footer
