import React, { Fragment } from 'react'
import { useParams, Link } from 'react-router-dom'

import ReactMarkdown from 'react-markdown'

import qs from 'qs'

import useFetch from '../../hooks/useFetch'

import creacionIcon from '../../assets/creacion.png'
import estrategiaIcon from '../../assets/estrategia.png'
import resposicionamientoIcon from '../../assets/reposicionamiento.png'
import experienciaIcon from '../../assets/experiencia.png'
import Arrow from '../../assets/arrow.png'

import HeroPageComponent from '../../components/HeroPage'
import FeedbackInside from '../../components/Feedback/FeedbackInside'
import CasesComponent from '../../components/Cases'
import ContactComponent from '../../components/Contact'
import Footer from '../../components/Footer'

import './CaseInside.scss'

const url_strapi = process.env.REACT_APP_URL_STRAPI
const url_api = `${process.env.REACT_APP_URL_STRAPI}/api`

const query = qs.stringify(
  {
    populate: [
      'Logo',
      'related_feedback',
      'related_feedback.ImageClient',
      'related_article',
      'related_article.Logo',
    ],
  },
  {
    encodeValuesOnly: true,
  }
)

const creacion = [
  'Relato de marca',
  'Identidad verbal',
  'Identidad visual',
  'Identidad sonora y animada',
]

const estrategia = [
  'Propósito de marca',
  'Propuesta de valor',
  'Arquitectura de marca',
  'Plataforma de marca',
]

const reposicionamiento = [
  'Análisis de categoría',
  'Cuadrante de actores clave',
  'Rediseño de identidad',
  'Lanzamiento de Rebranding',
]

const experiencia = [
  'Diseño del viaje del cliente',
  'Diseño de experiencia cliente',
  'Diseño UX',
  'Diseño UI',
]

function CaseInside() {
  const { slug } = useParams()

  const { loading, error, data } = useFetch(`${url_api}/articles/${slug}?${query}`)

  if (loading)
    return (
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    )

  if (error)
    return (
      <div className="container">
        <p>error...</p>
      </div>
    )

  return (
    <Fragment>
      <section className="container container-caseInsidePage">
        <HeroPageComponent
          breadcrumb={data.data.attributes.Client}
          title={data.data.attributes.Title}
        />
      </section>

      <section className="container container-caseInsideBody">
        <div className="wrapper-caseInsideImage">
          <img src={`${url_strapi}${data.data.attributes.Logo.data.attributes.url}`} alt="" />
        </div>

        <div className="wrapper-caseInsideBody">
          <ReactMarkdown>{data.data.attributes.Body}</ReactMarkdown>
        </div>
      </section>

      {data.data.attributes.related_feedback.data ? (
        <FeedbackInside
          inside="false"
          imageClient={`${url_strapi}${data.data.attributes.related_feedback.data.attributes.ImageClient.data.attributes.url}`}
          client={data.data.attributes.related_feedback.data.attributes.Client}
          positionClient={data.data.attributes.related_feedback.data.attributes.PositionClient}
          testimonial={data.data.attributes.related_feedback.data.attributes.TestimonialClient}
        />
      ) : (
        ''
      )}

      <section className="container container-caseInsideServices">
        <div className="wrapper-caseInsideServices">
          <div className="wrapper-title">
            <h2>Servicios involucrados:</h2>
          </div>

          <div className="wrapper-body">
            <ul>
              {data.data.attributes.Servicess.services.map((service, index) => (
                <li key={index}>
                  <span>{service}</span>
                </li>
              ))}
            </ul>
          </div>

          <div className="wrapper-servicesPage">
            <div className="card-service">
              <div className="title-card">
                <h2>
                  <img src={creacionIcon} alt="" />
                  Creacion
                </h2>
              </div>
              <div className="body-card">
                <ul>
                  {creacion.map((service, index) => (
                    <li
                      key={index}
                      className={
                        data.data.attributes.Servicess.services.includes(service) ? 'selected' : ''
                      }
                    >
                      <span>{service}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="card-service">
              <div className="title-card">
                <h2>
                  <img src={estrategiaIcon} alt="" />
                  Estrategia
                </h2>
              </div>
              <div className="body-card">
                <ul>
                  {estrategia.map((service, index) => (
                    <li
                      key={index}
                      className={
                        data.data.attributes.Servicess.services.includes(service) ? 'selected' : ''
                      }
                    >
                      <span>{service}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="card-service">
              <div className="title-card">
                <h2>
                  <img src={resposicionamientoIcon} alt="" />
                  Resposicionamiento
                </h2>
              </div>
              <div className="body-card">
                <ul>
                  {reposicionamiento.map((service, index) => (
                    <li
                      key={index}
                      className={
                        data.data.attributes.Servicess.services.includes(service) ? 'selected' : ''
                      }
                    >
                      <span>{service}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="card-service">
              <div className="title-card">
                <h2>
                  <img src={experienciaIcon} alt="" />
                  Experiencia
                </h2>
              </div>

              <div className="body-card">
                <ul>
                  {experiencia.map((service, index) => (
                    <li
                      key={index}
                      className={
                        data.data.attributes.Servicess.services.includes(service) ? 'selected' : ''
                      }
                    >
                      <span>{service}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          <div className="wrapper-external">
            <Link to="/servicios">
              Conoce más sobre nuestros servicios <img src={Arrow} alt="" />
            </Link>
          </div>
        </div>
      </section>

      <section className="container container-caseInsideRelated">
        <div className="wrapper-caseInsideRelated">
          <div className="wrapper-title">
            <h2>Conoce otros casos:</h2>
          </div>

          <CasesComponent
            title={data.data.attributes.related_article.data.attributes.Title}
            img={`${url_strapi}${data.data.attributes.related_article.data.attributes.Logo.data.attributes.url}`}
            client={data.data.attributes.related_article.data.attributes.Client}
            description={data.data.attributes.related_article.data.attributes.Description}
            url={`/casos/${data.data.attributes.related_article.data.attributes.Slug}`}
          />
        </div>
      </section>

      <ContactComponent />

      <Footer />
    </Fragment>
  )
}

export default CaseInside
