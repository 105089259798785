import React from 'react'
import { isMobile } from 'react-device-detect'
import { Link } from 'react-router-dom'

import Arrow from '../../assets/arrow.png'

import './Contact.scss'

const Contact = () => {
  return (
    <section className="container container-contact">
      <div className="wrapper-contact">
        <h1>
          Hablemos <br /> sobre tu marca
        </h1>

        <Link to="/contactanos">
          <img src={Arrow} alt="" />
          {isMobile ? 'Contáctanos.' : 'Contáctanos y agendamos una reunión.'}
        </Link>
      </div>
    </section>
  )
}

export default Contact
