import React, { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import useFetch from '../../hooks/useFetch'

import HeroPageComponent from '../../components/HeroPage'
import CasesComponent from '../../components/Cases'
import ServicesComponent from '../../components/Services'
import FeedbackComponent from '../../components/Feedback'

import ContactComponent from '../../components/Contact'
import Footer from '../../components/Footer'

import creacionIcon from '../../assets/creacion.svg'
import estrategiaIcon from '../../assets/estrategia.svg'
import resposicionamientoIcon from '../../assets/reposicionamiento.svg'
import experienciaIcon from '../../assets/experiencia.svg'
import Arrow from '../../assets/arrow.png'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import './Home.scss'

const ServicesArray = [
  {
    img: creacionIcon,
    title: 'Creacion',
    services: [
      'Relato de marca',
      'Identidad verbal',
      'Identidad Visual',
      'Identidad sonora y animada',
    ],
  },
  {
    img: estrategiaIcon,
    title: 'Estrategia',
    services: ['Propósito de marca', 'Arquitectura', 'Propuesta de valor', 'Ecosistema de marca'],
  },
  {
    img: resposicionamientoIcon,
    title: 'Reposicionamiento',
    services: [
      'Análisis de categoría',
      'Cuadrante de actores clave',
      'Rediseño de identidad',
      'Lanzamiento rebranding',
    ],
  },
  {
    img: experienciaIcon,
    title: 'Experiencia',
    services: [
      'Trazado del viaje del cliente',
      'Mejora de experiencia (CX)',
      'Diseño de interacción (UX)',
      'Sistema gráfico de interfaz (UI)',
    ],
  },
]

const url_strapi = process.env.REACT_APP_URL_STRAPI
const url_api = `${process.env.REACT_APP_URL_STRAPI}/api`

const HeroTitle = '¿Cuál es nuestro propósito sino un diseño digno?'
const HeroSubtitle = 'Diseñamos marcas que se atreven a cambiar el mundo.'

const loopArr = ['humanas', 'innovadoras', 'disruptivas']

const Home = () => {
  const [index, setIndex] = useState(0)

  useEffect(() => {
    const intervalDelayMilliseconds = loopArr[index].length * 500

    const interval = setInterval(() => {
      setIndex((prevIndex) => {
        return prevIndex + 1 < loopArr.length ? prevIndex + 1 : 0
      })
    }, intervalDelayMilliseconds)

    return () => clearInterval(interval)
  })

  const { loading, error, data } = useFetch(`${url_api}/articles?populate=*`)
  if (loading)
    return (
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    )
  if (error)
    return (
      <div className="container">
        <p>error...</p>
      </div>
    )

  return (
    <Fragment>
      <section className="container container-heroPage">
        <HeroPageComponent title={HeroTitle} subtitle={HeroSubtitle} />
      </section>

      <section className="container container-cases">
        <div className="wrapper-cases">
          {data.data
            .filter((o) => o.attributes.Destacado === true)
            .map((singleCase, index) => (
              <CasesComponent
                key={index}
                title={singleCase.attributes.Title}
                img={`${url_strapi}${singleCase.attributes.Logo.data.attributes.url}`}
                client={singleCase.attributes.Client}
                description={singleCase.attributes.Description}
                url={`/casos/${singleCase.attributes.Slug}`}
              />
            ))}

          <div className="wrapper-external">
            <Link to="/casos">Ve más casos en nuestro portafolio</Link>
          </div>
        </div>
      </section>

      <section className="container container-services">
        <div className="wrapper-services">
          <div className="wrapper-services-title">
            <h1>
              Construir marcas más
              <span key={loopArr[index]}>{loopArr[index]}</span> <br />
              es nuestro propósito.
            </h1>
            <p>
              Diseñamos marcas desde una visión holística, abordando desde su concepción hasta la
              experiencia que entregan.
            </p>
          </div>

          <div className="wrapper-services-list">
            <div className="content-service-list">
              {ServicesArray.map((service, index) => (
                <ServicesComponent
                  key={index}
                  title={service.title}
                  img={service.img}
                  services={service.services.map((service, index) => (
                    <li key={index}>{service}</li>
                  ))}
                />
              ))}

              <div className="wrapper-external">
                <Link to="/servicios">
                  Conoce más sobre nuestros servicios <img src={Arrow} alt="" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <FeedbackComponent />

      <ContactComponent />

      <Footer />
    </Fragment>
  )
}

export default Home
