import React, { Fragment, useState } from 'react'

import emailjs from 'emailjs-com'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import HeroPageComponent from '../../components/HeroPage'
import Footer from '../../components/Footer'

import heroPage from '../../assets/heroContact.jpg'
import logoWsp from '../../assets/logoWsp.png'

import './Contact.scss'

const Contact = () => {
  const [sendingMail, setSendingMail] = useState(false)
  const [contact, setContact] = useState({})
  const MySwal = withReactContent(Swal)

  const handleInputChange = async (event) => {
    await setContact({
      ...contact,
      [event.target.name]: event.target.value,
    })
  }

  const sendEmailReservation = async (contact) => {
    await emailjs
      .send('service_5omwn6n', 'template_1zt98vi', contact, 'user_cmZcKnh06XWJbNGq0vOWG')
      .then(async () => {
        await MySwal.fire({
          didOpen: () => {
            MySwal.clickConfirm()

            setSendingMail(false)
          },
        }).then(() => {
          return MySwal.fire({
            title: <p>Solicitud de contacto realizada</p>,
            text: 'Pronto te contactaremos.',
          })
        })

        setSendingMail(false)
      })
      .catch((error) => {
        console.log(error)

        setSendingMail(false)
      })
  }

  const sendData = async (event) => {
    event.preventDefault()
    event.target.reset()
    try {
      setSendingMail(true)

      await sendEmailReservation(contact)
    } catch (error) {
      console.log('Error al enviar el formulario')
    }
  }

  return (
    <Fragment>
      <section className="container container-contactHeroPage container-heroPage">
        <HeroPageComponent
          breadcrumb="Contáctanos"
          title={'Descubramos juntos la chispa de tu marca'}
        />

        <div className="wrap-contactGrid Mobile">
          <ul>
            <li>Puedes escribirnos a:</li>
            <li>
              <a href="mailto:hablemos@digno.cl">hablemos@digno.cl</a>
            </li>
          </ul>
        </div>

        <div className="wrap-contactGrid Desktop">
          <ul>
            <li>Puedes escribirnos a:</li>
            <li>
              <a href="mailto:hablemos@digno.cl">hablemos@digno.cl</a>
            </li>
          </ul>
          <ul>
            <li>Hablamos por WhatsApp:</li>
            <li>
              <a href="https://api.whatsapp.com/send?phone=">
                <img src={logoWsp} alt="" /> Iniciemos un chat
              </a>
            </li>
          </ul>
        </div>
      </section>

      <section className="container container-form">
        <div className="wrapper-img">
          <img src={heroPage} alt="" />
        </div>

        <div className="container">
          <div className="wrapper-form">
            <h2>O déjanos tu consulta y te contactamos:</h2>
          </div>

          <form onSubmit={sendData}>
            <div className="wrap-input">
              <input
                type="text"
                name="user_name"
                id="user_name"
                placeholder="Ingresa tu nombre y apellido*"
                required
                onChange={handleInputChange}
              />
            </div>

            <div className="wrap-input">
              <select
                name="user_bussines"
                id="user_bussines"
                defaultValue={'DEFAULT'}
                onChange={handleInputChange}
              >
                <option value="DEFAULT" disabled>
                  Selecciona tu tipo de empresa
                </option>
                <option value="startup">Startup</option>
                <option value="pyme">Pyme</option>
                <option value="agencia">Agencia</option>
                <option value="corporacion">Corporación</option>
                <option value="holding">Holding</option>
                <option value="fundacion">Fundación</option>
              </select>
            </div>

            <div className="wrap-input">
              <input
                type="text"
                name="user_email"
                id="user_email"
                placeholder="Ingresa tu e-mail*"
                required
                onChange={handleInputChange}
              />
            </div>

            <div className="wrap-input">
              <input
                type="text"
                name="user_phone"
                id="user_phone"
                placeholder="Ingresa tu teléfono*"
                required
                onChange={handleInputChange}
              />
            </div>

            <div className="wrap-input">
              <textarea
                name="user_message"
                id="user_message"
                cols="10"
                rows="20"
                placeholder="Dejanos tu consulta*"
                required
                onChange={handleInputChange}
              ></textarea>
            </div>

            <div className="wrap-input-btn">
              <span>* Campos requeridos para el envío.</span>
              <button type="submit">{sendingMail ? 'Enviando' : 'Envíanos tu consulta'}</button>
            </div>
          </form>
        </div>
      </section>

      <Footer />
    </Fragment>
  )
}

export default Contact
