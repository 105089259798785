import React, { Fragment, useState } from 'react'
import { NavLink, Link } from 'react-router-dom'

import LogoNav from '../../assets/logoNavbar.svg'

import IconIg from '../../assets/igIcon.svg'
import IconIn from '../../assets/inIcon.svg'
import IconBe from '../../assets/beIcon.svg'
import IconMe from '../../assets/mdmIcon.svg'

import ArrowMenu from '../../assets/ArrowMenu.png'

import './Header.scss'

const Header = () => {
  const [openMenu, setOpenMenu] = useState(false)

  const handleMenu = () => {
    setOpenMenu(!openMenu)
    document.querySelector('body').classList.toggle('menu-open')
  }
  const closeMenu = () => {
    setOpenMenu(false)
    document.querySelector('body').classList.remove('menu-open')
  }

  return (
    <Fragment>
      <header>
        <div className="container-header">
          <div className="wrapper-navbar">
            <div className="container-logo">
              <Link onClick={closeMenu} to="/">
                <img src={LogoNav} alt="" />
              </Link>
            </div>

            <div className="container-btnMenu">
              <div className="container-btn">
                <button onClick={handleMenu} className={openMenu ? 'open' : ''}>
                  <span></span>
                  <span></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className={`container-menu ${openMenu ? 'open' : ''}`}>
        <nav className="wrapper-menu">
          <ul>
            <li>
              <img src={ArrowMenu} alt="" />
              <NavLink
                to="/casos"
                onClick={handleMenu}
                style={({ isActive }) => ({ status: isActive ? 'active' : '' })}
              >
                Casos
              </NavLink>
            </li>
            <li>
              <img src={ArrowMenu} alt="" />
              <NavLink
                to="/servicios"
                onClick={handleMenu}
                style={({ isActive }) => ({ status: isActive ? 'active' : '' })}
              >
                Servicios
              </NavLink>
            </li>
            <li>
              <img src={ArrowMenu} alt="" />
              <NavLink
                to="/nosotros"
                onClick={handleMenu}
                style={({ isActive }) => ({ status: isActive ? 'active' : '' })}
              >
                Nosotros
              </NavLink>
            </li>
            <li>
              <img src={ArrowMenu} alt="" />
              <NavLink
                to="/contactanos"
                onClick={handleMenu}
                style={({ isActive }) => ({ status: isActive ? 'active' : '' })}
              >
                Contáctanos
              </NavLink>
            </li>
          </ul>
        </nav>

        <div className="container container-rrss">
          <div className="wrapper-rrss desktop">
            <ul>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.instagram.com/digno_branding/"
                >
                  <img src={IconIg} alt="" />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.linkedin.com/company/digno-consultores-de-marca/"
                >
                  <img src={IconIn} alt="" />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.youtube.com/channel/UC40pkI6M_KpXnVogLTK0n_A"
                >
                  <img src={IconBe} alt="" />
                </a>
              </li>
              <li>
                <a target="_blank" rel="noreferrer" href="https://medium.com/@digno_branding">
                  <img src={IconMe} alt="" />
                </a>
              </li>
            </ul>
          </div>

          <div className="wrapper-info mobile">
            <ul>
              <li>
                © <b>Digno</b>
              </li>
              <li>Consultores de Marca</li>
              <li>La Concepción 140, Oficina 102.</li>
              <li>Providencia, Santiago de Chile.</li>
              <li>
                <b>Teléfono:</b> +56 22 840 9500.
              </li>
              <li>
                <b>E-mail:</b> <a href="mailto:hablemos@digno.cl">hablemos@digno.cl</a>
              </li>
            </ul>
          </div>

          <div className="wrapper-info desktop">
            <ul>
              <li>
                © <b>Digno</b> Consultores de Marca{' '}
              </li>
              <li> La Concepción 140, Oficina 102. Providencia, Santiago de Chile.</li>
              <li>
                <b>Teléfono:</b> +56 22 840 9500.
                <b> E-mail:</b> <a href="mailto:hablemos@digno.cl">hablemos@digno.cl</a>
              </li>
            </ul>
          </div>

          <div className="wrapper-rrss mobile">
            <ul>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.instagram.com/digno_branding/"
                >
                  <img src={IconIg} alt="" />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.linkedin.com/company/digno-consultores-de-marca/"
                >
                  <img src={IconIn} alt="" />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.youtube.com/channel/UC40pkI6M_KpXnVogLTK0n_A"
                >
                  <img src={IconBe} alt="" />
                </a>
              </li>
              <li>
                <a target="_blank" rel="noreferrer" href="https://medium.com/@digno_branding">
                  <img src={IconMe} alt="" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Header
