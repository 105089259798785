import React from 'react'

const Services = (props) => {
  return (
    <div className="card-service">
      <div className="card-service-img">
        <img src={props.img} alt="" />
      </div>

      <h2>{props.title}</h2>
      <ul>{props.services}</ul>
    </div>
  )
}

export default Services
