import React from 'react'

import './HeroPage.scss'

const HeroPage = (props) => {
  return (
    <div className="wrapper-heroPage">
      <div className="wrap-breadcrumb">
        <span>{props.breadcrumb}</span>
      </div>

      <div className="wrap-title">
        <h1>{props.title}</h1>
      </div>

      <div className="wrap-subtitle">
        <h2>{props.subtitle}</h2>
      </div>
    </div>
  )
}

export default HeroPage
