import React, { Fragment, useEffect, useState } from 'react'

import HeroPageComponent from '../../components/HeroPage'
import CasesComponent from '../../components/Cases'
import FeedbackComponent from '../../components/Feedback'
import ContactComponent from '../../components/Contact'
import Footer from '../../components/Footer'

import './Cases.scss'

const url_strapi = process.env.REACT_APP_URL_STRAPI
const url_api = `${process.env.REACT_APP_URL_STRAPI}/api`

const Cases = () => {
  const [cases, setCases] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      const res = await fetch(`${url_api}/articles?populate=*`)
      const posts = await res.json()

      setCases(posts.data)
    }

    fetchData()
  }, [])

  return (
    <Fragment>
      <section className="container container-casesHeroPage container-heroPage">
        <HeroPageComponent
          breadcrumb={'Casos'}
          title={'Diseñamos marcas que entregan algo único al mundo'}
        />
      </section>

      <section className="container container-cases">
        <div className="wrapper-cases">
          {cases.map((singleCase, index) => (
            <CasesComponent
              key={index}
              title={singleCase.attributes.Title}
              img={`${url_strapi}${singleCase.attributes.Logo.data.attributes.url}`}
              client={singleCase.attributes.Client}
              description={singleCase.attributes.Description}
              url={`/casos/${singleCase.attributes.Slug}`}
            />
          ))}
        </div>
      </section>

      <FeedbackComponent />

      <ContactComponent />

      <Footer />
    </Fragment>
  )
}

export default Cases
