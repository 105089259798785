import React from 'react'
import { Link, useLocation } from 'react-router-dom'

import Arrow from '../../assets/arrow.png'

import './Cases.scss'

const Cases = (props) => {
  const location = useLocation()

  return (
    <div className="card">
      <div className="card-image">
        <Link to={props.url}>
          <img src={props.img} alt={props.title} />
        </Link>
      </div>
      <div className={`card-info ${location.pathname === '/casos' ? 'short' : ''}`}>
        <div className="card-info-wrapper">
          <div className="card-info-title">
            {location.pathname === '/casos' ? '' : <span>Caso destacado:</span>}
            <h1>{props.title}</h1>
          </div>

          <div className="card-info-case">
            <span>
              Cliente: <b>{props.client}</b>
            </span>
            <p>{props.description}</p>
          </div>

          <div className="card-info-action">
            <Link to={props.url}>
              Ver caso <img src={Arrow} alt="" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Cases
