import React, { Fragment } from 'react'

import HeroPageComponent from '../../components/HeroPage'
import FeedbackComponent from '../../components/Feedback'
import ContactComponent from '../../components/Contact'
import Footer from '../../components/Footer'

import headerServices from '../../assets/headerServices.png'

import creacionIcon from '../../assets/creacion.svg'
import estrategiaIcon from '../../assets/estrategia.svg'
import resposicionamientoIcon from '../../assets/reposicionamiento.svg'
import experienciaIcon from '../../assets/experiencia.svg'

import './Services.scss'

const Services = () => {
  return (
    <Fragment>
      <section className="container container-servicesHeroPage container-container-heroPage">
        <HeroPageComponent
          breadcrumb="Servicios"
          title="Ayudamos a construir marcas a escala humana"
        />
      </section>

      <div className="container container-heroServices">
        <div className="wrapper-img">
          <img src={headerServices} alt="" />
        </div>

        <div className="container wrapper-heroServices">
          <h2>Descubrimos esa chispa que ayudará a tu marca a cambiar el mundo.</h2>
          <p>Dando vida a una marca de la cual las personas quieran ser parte.</p>
        </div>
      </div>

      <section className="container container-servicesPage">
        <div className="wrapper-servicesPage">
          <div className="card-service">
            <div className="title-card">
              <h2>
                <img src={creacionIcon} alt="" />
                Creación
              </h2>
            </div>
            <div className="body-card">
              <p>
                Diseñamos un lenguaje identitario concebido en las raíces de tu marca, para llevar
                su personalidad y visión al mundo.
              </p>
              <ul>
                <li>
                  Relato de marca
                  <p>
                    Creamos una narrativa que transmita de forma simple los valores que conectarán a
                    tu marca con sus clientes
                  </p>
                </li>
                <li>
                  Identidad verbal
                  <p>
                    Creamos el nombre que tendrá tu marca (brand naming) llevando la personalidad de
                    tu marca a una palabra que conecte con sus clientes.
                  </p>
                </li>
                <li>
                  Identidad visual
                  <p>
                    Diseñamos un lenguaje identitario concebido en las raíces de tu marca, para
                    llevar su personalidad y visión al mundo.
                  </p>
                </li>
                <li>
                  Identidad sonora y animada
                  <p>
                    Diseñamos el tipo de animación de tu identidad gráfica y los sonidos asociados a
                    ella, buscando expresar fielmente la personalidad de tu marca.
                  </p>
                </li>
              </ul>
            </div>
          </div>

          <div className="card-service">
            <div className="title-card">
              <h2>
                <img src={estrategiaIcon} alt="" />
                Estrategia
              </h2>
            </div>
            <div className="body-card">
              <p>
                Definimos el perfil actitudinal del cliente deseado para la marca, trazando los
                caminos y acciones que nos ayudarán a crear lazos duraderos con ellos.
              </p>
              <ul>
                <li>
                  Propósito de marca.
                  <p>
                    Identificamos lo que da sentido a tu negocio y lo transformamos en el motor de
                    tu compañía, no sólo en una declaración de principios.
                  </p>
                </li>
                <li>
                  Propuesta de valor
                  <p>
                    Identificamos aquello que sólo tu compañía puede entregar al mundo. Eso que hace
                    a tu compañía imprescindible.
                  </p>
                </li>
                <li>
                  Arquitectura de marca
                  <p>
                    ¿Monolítica, de endoso o independiente? Te ayudamos a definir el tipo de
                    relación de las submarcas de tu compañía.
                  </p>
                </li>
                <li>
                  Plataforma de marca.
                  <p>
                    Desarrollamos un documento que funcione como la piedra angular para construir
                    sobre ella una marca robusta y coherente.
                  </p>
                </li>
              </ul>
            </div>
          </div>

          <div className="card-service">
            <div className="title-card">
              <h2>
                <img src={resposicionamientoIcon} alt="" />
                Reposicionamiento
              </h2>
            </div>
            <div className="body-card">
              <p>
                Ayudamos a redefinir la forma en que tus clientes perciben a tu marca, analizando
                los actores de tu categoría, el mercado, tus clientes deseados y el zeitgeist.
              </p>
              <ul>
                <li>
                  Análisis de categoría
                  <p>
                    Investigamos el escenario y las empresas de una categoría, identificando las
                    oportunidades para tu marca.
                  </p>
                </li>
                <li>
                  Cuadrante de actores clave
                  <p>
                    Mapeamos el posicionamiento de las principales empresas de una categoría, para
                    descubrir dónde se ubica tu compañía.
                  </p>
                </li>
                <li>
                  Rediseño de identidad
                  <p>
                    Evolucionamos la identidad de tu marca, a fin de hacerla congruente con el nuevo
                    posicionamiento deseado.
                  </p>
                </li>
                <li>
                  Rebranding
                  <p>
                    Diseñamos la comunicación inicial del nuevo branding, bajo un nuevo relato
                    basado en la evolución de tu marca.
                  </p>
                </li>
              </ul>
            </div>
          </div>

          <div className="card-service">
            <div className="title-card">
              <h2>
                <img src={experienciaIcon} alt="" />
                Experiencia
              </h2>
            </div>
            <div className="body-card">
              <p>
                Analizamos el viaje de tu cliente, identificando las etapas que requieren mayor
                eficiencia de recursos y potenciando aquellas clave para lograr una gran experiencia
                de cliente.
              </p>
              <ul>
                <li>
                  Diseño del viaje del cliente.
                  <p>
                    Trazamos el viaje del cliente, identificando los puntos de optimización de
                    recursos y mejoras clave en los key pain points.
                  </p>
                </li>
                <li>
                  Diseño de experiencia del cliente.
                  <p>
                    Tras tener identificado el punto clave de mejora, diseñamos la experiencia que
                    potenciará todo el viaje de tus clientes.
                  </p>
                </li>
                <li>
                  Diseño UX
                  <p>
                    Creamos experiencias satisfactorias para tus clientes frente a la interacción de
                    tus productos digitales (web/apps/plataformas).
                  </p>
                </li>
                <li>
                  Diseño UI.
                  <p>
                    Diseñamos el sistema gráfico de la interfaz de los productos digitales de tu
                    marca (web/apps/plataformas).
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <FeedbackComponent />

      <ContactComponent />

      <Footer />
    </Fragment>
  )
}

export default Services
