import React from 'react'
import Slider from 'react-slick'

import useFetch from '../../hooks/useFetch'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import './Feedback.scss'

const url_strapi = process.env.REACT_APP_URL_STRAPI
const url_api = `${process.env.REACT_APP_URL_STRAPI}/api`

const Feedback = (props) => {
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          dots: true,
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          fade: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          fade: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  const { loading, error, data } = useFetch(`${url_api}/feedbacks?populate=*`)
  if (loading)
    return (
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    )
  if (error)
    return (
      <div className="container">
        <p>error...</p>
      </div>
    )

  return (
    <section className="container container-feedback">
      <div className="wrapper-feedback">
        {props.inside ? (
          ''
        ) : (
          <div className="wrapper-feedback-title">
            <h1>Nuestros clientes dicen:</h1>
          </div>
        )}

        <div className="wrapper-feedback-slide">
          <Slider {...settings}>
            {data.data.map((feedback, index) => (
              <div key={index} className="slide-feedback">
                <div className="card-clientFeedback desktop">
                  <div className="wrap-img">
                    <img
                      src={`${url_strapi}${feedback.attributes.ImageClient.data.attributes.url}`}
                      alt=""
                    />
                  </div>
                  <div className="wrap-info">
                    <h2>{feedback.attributes.Client}</h2>
                    <h3>{feedback.attributes.PositionClient}</h3>
                  </div>
                </div>

                <p>{feedback.attributes.TestimonialClient}</p>

                <div className="card-clientFeedback mobile">
                  <div className="wrap-img">
                    <img
                      src={`${url_strapi}${feedback.attributes.ImageClient.data.attributes.url}`}
                      alt=""
                    />
                  </div>
                  <div className="wrap-info">
                    <h2>{feedback.attributes.Client}</h2>
                    <h3>{feedback.attributes.PositionClient}</h3>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  )
}

export default Feedback
