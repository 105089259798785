import React from 'react'
import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom'

import ScrollToTop from '../hooks/ScrollTop'

import Header from '../components/Header'
import Home from '../pages/Home'
import About from '../pages/About'
import Services from '../pages/Services'
import Cases from '../pages/Cases'
import CaseInside from '../pages/CaseInside'
import Contact from '../pages/Contact'
import Error404 from '../pages/404'

function RoutesApp() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Header />
      <Routes>
        <Route path="/404" element={<Error404 />} />
        <Route path="*" element={<Navigate replace to="/404" />} />
        <Route path="/" element={<Home />} />
        <Route path="/casos" element={<Cases />} />
        <Route path="/servicios" element={<Services />} />
        <Route path="/nosotros" element={<About />} />
        <Route path="/contactanos" element={<Contact />} />
        <Route path="/casos/:slug" element={<CaseInside />} />
      </Routes>
    </BrowserRouter>
  )
}

export default RoutesApp
