import React from 'react'
import './Feedback.scss'

const FeedbackInside = (props) => {
  return (
    <section className="container container-feedback inside">
      <div className="wrapper-feedback">
        {props.inside ? (
          ''
        ) : (
          <div className="wrapper-feedback-title">
            <h1>Nuestros clientes dicen:</h1>
          </div>
        )}

        <div className="wrapper-feedback-slide">
          <div className="slide-feedback">
            <p>
              {`"`}
              {props.testimonial}
              {`"`}
            </p>

            <div className="card-clientFeedback">
              <div className="wrap-img">
                <div className="wrap-img">
                  <img src={props.imageClient} alt="" />
                </div>
              </div>
              <div className="wrap-info">
                <h2>{props.client}</h2>
                <h3>{props.positionClient}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FeedbackInside
